<template>
  <div class="container-page big">
    <HeaderBlock
      title="Инициативы о применении мер таможенно-тарифного регулирования"
      @create="createNew"
      @clear="clearFilter"
      @onLoadFilter="onLoadFilter"
      @export="exportRegistry"
      @switchHandler="changeView"
      @bulkEditing="bulkEditing"
      @editView="editView"
      :filterValue="filter"
      :importButton="false"
      :checkedSwitch="isBoardView"
      :selectedIds="selectedIds"
      :class="['header-block-measure--initiatives', { combine: isBoardView }]"
      saveFilter
      switchComponent
    />
    <div v-if="!isBoardView">
      <div class="table-shadow">
        <div class="table-container-base table-container-base--sort js-table-arrow">
          <table v-if="!changeLog.isActive">
            <HeaderTable
              :tableHeader="getTableHeaderInitiative"
              @changeSorting="changeSorting"
              @changeFilter="changeFilter"
              @changeSelectAll="changeSelectAll"
              ref="headerTable"
            />
            <TableBody :itemsBody="page.items" :isLoading="isLoading">
              <tr v-for="item in page.items" :key="item.id" @dblclick="editCard(item.id)">
                <td class="column-icon">
                  <IconComponent class="icon--full-svg" @click="editCard(item.id)" name="edit" />
                </td>
                <td class="width-46">
                  <input type="checkbox" v-model="selectedIds" :value="item.id" />
                </td>
                <td>{{ item.id }}</td>
                <td class="width-226" v-if="getVisibleItems.includes(tableIdColumns.employeeName)">
                  {{ item.employee?.fullName ?? '-' }}
                </td>
                <td class="width-226" v-if="getVisibleItems.includes(tableIdColumns.organizationName)">
                  {{ item.employee?.organization?.name ?? '-' }}
                </td>
                <td class="width-226" v-if="getVisibleItems.includes(tableIdColumns.organizationAddress)">
                  {{ item.employee?.organization?.address ?? '-' }}
                </td>
                <td class="width-226" v-if="getVisibleItems.includes(tableIdColumns.phone)">
                  {{ item?.employee?.organization?.phone ?? '-' }}
                </td>
                <td class="width-226" v-if="getVisibleItems.includes(tableIdColumns.director)">
                  {{ item.employee?.organization?.director ?? '-' }}
                </td>
                <td class="width-226" v-if="getVisibleItems.includes(tableIdColumns.registrationDate)">
                  {{
                    item.employee?.organization?.registrationDate
                      ? $momentFormat(item.employee.organization.registrationDate, 'DD.MM.YYYY')
                      : '-'
                  }}
                </td>
                <td class="width-226" v-if="getVisibleItems.includes(tableIdColumns.registrationAddress)">
                  {{ item.employee?.organization?.registrationAddress ?? '-' }}
                </td>
                <td class="width-226" v-if="getVisibleItems.includes(tableIdColumns.authorizedFund)">
                  {{ item.employee?.organization?.authorizedFund ?? '-' }}
                </td>
                <td class="width-226" v-if="getVisibleItems.includes(tableIdColumns.created)">
                  {{ $momentFormat(item.created, 'DD.MM.YYYY') }}
                </td>
                <td class="width-226" v-if="getVisibleItems.includes(tableIdColumns.tnvedGroupName)">
                  {{ item.tnvedGroup?.name ?? '-' }}
                </td>
                <td class="width-226" v-if="getVisibleItems.includes(tableIdColumns.tnvedCode)">
                  {{ getTnvedCodes(item) }}
                </td>
                <td class="width-226" v-if="getVisibleItems.includes(tableIdColumns.measureTypeId)">
                  {{ item?.measureType?.name ?? '-' }}
                </td>
                <td class="width-226" v-if="getVisibleItems.includes(tableIdColumns.description)">
                  {{ item?.description ?? '-' }}
                </td>
                <td class="width-226" v-if="getVisibleItems.includes(tableIdColumns.statusId)">
                  {{ item?.status?.name ?? '-' }}
                </td>
                <td class="width-226" v-if="getVisibleItems.includes(tableIdColumns.applicantGeneralInformation)">
                  {{ item?.applicantGeneralInformation ?? '-' }}
                </td>
                <td class="width-226" v-if="getVisibleItems.includes(tableIdColumns.tradingOperation)">
                  {{ item?.nsiTradingOperation?.name ?? '-' }}
                </td>
                <td class="width-226" v-if="getVisibleItems.includes(tableIdColumns.rates)">
                  <template v-if="item?.rates.length">
                    <div v-for="entry in item?.rates" :key="entry.id">{{ entry?.proposedSpecification ?? '-' }}</div>
                  </template>
                  <template v-else>
                    <div>-</div>
                  </template>
                </td>
                <td class="width-226" v-if="getVisibleItems.includes(tableIdColumns.purpose)">
                  {{ item?.purpose ?? '-' }}
                </td>
                <td class="width-226" v-if="getVisibleItems.includes(tableIdColumns.responsible)">
                  {{ item.responsibleEmployee?.fullName ?? '-' }}
                </td>
                <td class="width-226" v-if="getVisibleItems.includes(tableIdColumns.notes)">
                  {{ item?.notes ?? '-' }}
                </td>
                <td class="width-226" v-if="getVisibleItems.includes(tableIdColumns.organizationInfo)">
                  {{ item?.employee?.organization?.nameFull ?? '-' }}
                </td>
              </tr>
            </TableBody>
          </table>
        </div>
      </div>

      <PaginationRedesigned
        v-if="page.pagesTotal > 1"
        :page-count="page.pagesTotal"
        :current-page="page.pageNumber"
        @change="onPageChange($event, false)"
        @more="onPageChange($event, true)"
      />
    </div>
    <div class="card-view-container" v-if="isBoardView">
      <p @click="filterShow = !filterShow" class="title-inner textDecorUnderLine cursorPointer">Фильтрация данных</p>

      <div v-if="filterShow" class="cards-filter">
        <div class="cards-filter__input-container">
          <p class="title">Дата создания заявления</p>
          <div class="cards-filter__input-block">
            <p class="prefix">c</p>
            <div class="date">
              <dateComponent :clearable="true" v-model="filterBoard.createdFrom" />
            </div>
            <IconComponent name="dateRange" />
          </div>
          <div class="cards-filter__input-block">
            <p class="prefix">по</p>
            <div class="date">
              <dateComponent :clearable="true" v-model="filterBoard.createdTo" />
            </div>
            <IconComponent name="dateRange" />
          </div>
        </div>

        <div class="cards-filter__input-container">
          <p>Заявитель</p>
          <ComponentInput placeholder="Введите ФИО заявителя" v-model="filterBoard.employeeName" />
        </div>

        <div class="btns d-flex">
          <ButtonComponent mod="border" class="button-redesign--mobile-full" @click="clearBoard">Сброс</ButtonComponent>
          <ButtonComponent mod="gradient-bg" class="button-redesign--mobile-full" @click="searchBoard"
            >Искать</ButtonComponent
          >
        </div>
      </div>

      <div class="cards-container d-flex justify-content-between overflow-auto">
        <div
          class="cards-container__trading-item"
          v-for="tradeItem in getTtrStatusGroupOptions.filter((x) => x.idName > 0)"
          :key="tradeItem.idName"
        >
          <p class="cards-container__trading-item-title">{{ tradeItem.label }}</p>
          <div class="cards-container__statuses d-flex justify-content-between">
            <div class="cards-container__status-item" v-for="status in tradeItem.options" :key="status.value">
              <p class="cards-container__status-item-title">{{ status.label }}</p>

              <div class="cards-container__cards">
                <div
                  class="initiative-card-item"
                  v-for="item in initiatives.filter((x) => x.statusId == status.value)"
                  :key="item.id"
                >
                  <p class="initiative-card-item__name" @click="editCard(item.id)">Заявление № {{ item.id }}</p>
                  <div class="initiative-card-item__date">Дата: {{ $momentFormat(item.created, 'DD.MM.YYYY') }}</div>
                  <div class="initiative-card-item__section">
                    <div class="initiative-card-item__label">Организация</div>
                    <div class="initiative-card-item__author">
                      <div>
                        <p class="initiative-card-item__text">{{ item.employee?.organization?.name }}</p>
                      </div>
                    </div>
                  </div>
                  <div class="initiative-card-item__section">
                    <div class="initiative-card-item__label">Ответственный сотрудник</div>
                    <div class="initiative-card-item__author">
                      <img :src="getEmployeePicture(item?.employee?.picture)" :alt="item.employee?.fullName" />
                      <div>
                        <p class="initiative-card-item__text">{{ item.employee?.fullName }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ModalComponent
      v-model="bulkEditingModal"
      content-class="modal--new"
      title="Массовое редактирование записей"
      headType="redesigned"
      bodyType="redesigned"
      @close="closeModals('bulkEditingModal')"
      @update:modelValue="closeModals('bulkEditingModal')"
    >
      <ContentPopupBulkEditing
        :selectedItems="selectedIds"
        :statusOptions="getTtrStatusGroupOptions"
        :changePromotion="false"
        :changeDate="false"
        groupsSelect
        initiative
        @closePopup="closeModals('bulkEditingModal')"
        @save="bulkEditingChange"
        ref="bulkEditingModal"
      />
    </ModalComponent>

    <ModalComponent
      v-model="editViewModal"
      content-class="modal--new"
      title="Настройка вида отображения"
      headType="redesigned"
      bodyType="redesigned"
      @close="closeModals('editViewModal')"
      @update:modelValue="closeModals('editViewModal')"
    >
      <ContentPopupEditView
        :listCheckbox="getTableHeaderInitiative"
        @save="changeColumnTable"
        @closePopup="closeModals('editViewModal')"
        ref="editViewModal"
      />
    </ModalComponent>

    <!-- статус Массового редактирования и Настройка вида отображения -->
    <ModalInfo
      v-model="isOpenModalInfo"
      @closeModal="closeModalInfo"
      :titleModal="titleModal"
      :textModal="textModal"
      :modalIconName="modalIconName"
    />
  </div>
</template>

<script>
  import { mapActions, mapGetters, mapMutations } from 'vuex';

  import ComponentInput from '@/common/components/ComponentInput';
  import dateComponent from '@/common/components/dateComponent.vue';
  import ButtonComponent from '@/common/components/redesigned/ButtonComponent.vue';
  import ModalInfo from '@/common/components/redesigned/ModalInfo.vue';
  import PaginationRedesigned from '@/common/components/redesigned/PaginationRedesigned';
  import TableBody from '@/common/components/redesigned/TableBody';
  import Constants from '@/common/constants';
  import modalInfo from '@/common/mixins/modalInfo';
  import removeEmptyValuesFromObject from '@/common/mixins/removeEmptyValuesFromObject';
  import security from '@/common/mixins/security';
  import IconComponent from '@/common/ui/IconComponent';

  import ModalComponent from '@/components/modals/ModalComponent';

  import Api from '../api/reqsinitiativesttr';
  import ContentPopupBulkEditing from '../components/ContentPopupBulkEditing';
  import ContentPopupEditView from '../components/ContentPopupEditView';
  import HeaderBlock from '../components/HeaderBlock';
  import HeaderTable from '../components/initiativeComponents/HeaderTable';
  import duplicateMethods from '../mixins/duplicateMethods';
  import {
    INITIATIVE_REGISTRY_ACTIONS_NAME,
    INITIATIVE_REGISTRY_GETTERS_NAME,
    INITIATIVE_REGISTRY_MUTATIONS_NAME,
  } from '../store/initiativeRegistry/name.js';
  import { MODAL_INFO_MESSAGES, TABLE_ID_INITIATIVE } from '../utils/constants';

  export default {
    name: 'RequestInitiativeTtrRegistry',
    components: {
      ComponentInput,
      IconComponent,
      dateComponent,
      HeaderBlock,
      ButtonComponent,
      PaginationRedesigned,
      TableBody,
      ModalComponent,
      ContentPopupBulkEditing,
      ContentPopupEditView,
      ModalInfo,
      HeaderTable,
    },
    mixins: [security, removeEmptyValuesFromObject, duplicateMethods, modalInfo],
    data() {
      return {
        isBoardView: false,
        page: {},
        request: {
          pageNumber: 1,
          pageSize: Constants.pageSize,
        },
        selectedIds: [],
        selectAll: false,
        // selectedStatusId: null,
        isLoading: false,
        filter: {
          employeeName: '',
          organizationName: '',
          organizationAddress: '',
          phone: '',
          director: '',
          registrationDateFrom: null,
          registrationDateTo: null,
          registrationAddress: '',
          authorizedFundFrom: '',
          authorizedFundTo: '',
          createdFrom: null,
          createdTo: null,
          tnvedGroupName: '',
          tnvedCode: '',
          measureTypeId: null,
          description: '',
          statusId: null,
        },
        filterBoard: {},
        filterShow: false,
        initiatives: [],
        canCreate: false,

        bulkEditingModal: false,
        tableIdColumns: TABLE_ID_INITIATIVE,
        editViewModal: false,
      };
    },
    created() {
      this.loadUser();
      this.loadDictionaries();
      this.loadPage(false);
    },
    computed: {
      ...mapGetters({
        getTableHeaderInitiative: INITIATIVE_REGISTRY_GETTERS_NAME.getTableHeaderInitiative,
      }),

      getVisibleItems() {
        return this.getTableHeaderInitiative.filter((i) => i.visible).map((i) => i.id);
      },
    },
    methods: {
      ...mapActions({
        changeParametersMulti: INITIATIVE_REGISTRY_ACTIONS_NAME.changeParametersMulti,
      }),

      ...mapMutations({
        updateListTableHeaderInitiative: INITIATIVE_REGISTRY_MUTATIONS_NAME.updateListTableHeaderInitiative,
      }),

      loadUser() {
        this.checkPermission(Constants.Permissions.RequestInitiativeTtrCreateAdmin).then((response) => {
          this.canCreate = response.data;
        });
      },

      loadPage(append) {
        this.isLoading = !append;
        const dataFilter = this.objectFormatter(this.filter);
        Api.search({
          ...this.request,
          ...dataFilter,
        })
          .then((response) => {
            if (!append) {
              this.page = {};
              this.$nextTick(() => {
                this.page = response.data;
              });
            } else if (response.data) {
              this.page.pageNumber = response.data.pageNumber;
              this.page.items = this.page.items.concat(response.data.items);
            }
            this.isLoading = false;
          })
          .catch(() => {
            this.isLoading = false;
          });
      },

      loadDictionaries() {
        this.getNSITtrStatus();
        this.getNSIMeasureTypes();
      },

      editCard(id) {
        this.$router.push({ name: 'RequestInitiativeTtrCard', params: { id: id, action: 'edit' } });
      },

      exportRegistry() {
        Api.exportRegistry(this.request);
      },

      async bulkEditingChange(data) {
        const res = await this.changeParametersMulti({
          ...data,
          ids: this.selectedIds,
        });
        this.closeModals('bulkEditingModal');
        this.titleModal = MODAL_INFO_MESSAGES.SAVE_TITLE;
        if (res.success) {
          this.textModal = MODAL_INFO_MESSAGES.SAVE_TEXT;
          this.modalIconName = Constants.MODAL_INFO_ICON.ACCEPT;
          this.isOpenModalInfo = true;
          this.loadPage();
        } else {
          this.textModal = MODAL_INFO_MESSAGES.ERROR_TEXT;
          this.modalIconName = Constants.MODAL_INFO_ICON.ERROR;
          this.isOpenModalInfo = true;
        }
      },

      bulkEditing() {
        this.bulkEditingModal = true;
      },

      clearSelection() {
        this.selectedIds = [];
      },

      onPageChange(pageNumber, addToExist) {
        this.request.pageNumber = pageNumber;
        this.loadPage(addToExist);
      },

      createNew() {
        this.$router.push({ name: 'RequestInitiativeTtrCardAdd' });
      },

      changeView(isBoard) {
        this.isBoardView = isBoard;
        if (this.isBoardView) this.searchBoard();
      },

      searchBoard() {
        const params = Object.assign(this.filterBoard, { pageNumber: 1, pageSize: 0 });
        Api.search(params).then((response) => {
          this.initiatives = response.data.items;
        });
      },

      getEmployeePicture(pic) {
        return pic ?? require('@/assets/images/no_photo.png');
      },

      clearBoard() {
        this.filterBoard = {};
        this.searchBoard();
      },

      onLoadFilter(item) {
        this.$refs.headerTable.filter = item;
      },

      closeModals(type) {
        this[type] = false;
        if (this.$refs[type]) {
          this.$refs[type].reset();
        }
      },

      changeSelectAll(value) {
        if (value) {
          this.selectedIds = [];
          this.selectedIds.push(...this.page.items.map((x) => x.id));
        } else {
          this.clearSelection();
        }
      },

      changeSorting(sort) {
        const { sortColumn, sortOrder } = sort;
        this.request.sortOrder = sortOrder;
        this.request.sortColumn = sortColumn;
        this.loadPage();
      },

      changeFilter(filter) {
        this.request.pageNumber = 1;
        this.filter = filter;
        this.loadPage();
      },

      clearFilter() {
        this.filter = {};
        this.$refs.headerTable.clearFilter();
        this.loadPage();
      },

      editView() {
        this.editViewModal = true;
      },

      changeColumnTable(array) {
        this.updateListTableHeaderInitiative(array);
        this.titleModal = MODAL_INFO_MESSAGES.SAVE_TITLE;
        this.textModal = MODAL_INFO_MESSAGES.SAVE_TEXT;
        this.modalIconName = Constants.MODAL_INFO_ICON.ACCEPT;
        this.closeModals('editViewModal');
        this.isOpenModalInfo = true;
      },

      getTnvedCodes(item) {
        if (item.tnved?.code) {
          return item.tnved.code;
        }

        if (item?.tnvedCodes) {
          return item.tnvedCodes;
        }

        return '-';
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '../styles/index.scss';
  @import '@/assets/theme/default/table/table-base.scss';

  .cards-container {
    min-height: 70vh;
    &__trading-item {
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.08);
      width: auto;
      background: #fafafa;
      padding: 18px 10px 0px 10px;
      margin-right: 16px;
      &-title {
        margin-bottom: 16px;
        font: $fira-16;
        text-transform: uppercase;
        font-weight: 500;
        color: $black-st;
      }
    }
    &__statuses {
      min-height: 70vh;
    }
    &__status-item {
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.08);
      max-width: 276px;
      width: 276px;
      background: #eaeaea;
      padding: 18px 10px 0px 10px;
      margin-right: 8px;
      &-title {
        font: $fira-14;
        text-transform: uppercase;
        font-weight: 500;
        min-height: 165px;
        max-height: 165px;
        padding-bottom: 16px;
        margin-bottom: 14px;
        border-bottom: 1px solid $dark-grey-1;
        color: $black-st;
      }
    }
    &__cards {
      max-height: calc(70vh - 200px);
      overflow-y: auto;
    }
  }

  .initiative-card-item {
    padding: 16px 12px;
    border: 1px solid rgba(50, 50, 50, 0.3);
    background: #f3f3f3;
    &__name {
      font: $fira-16;
      font-weight: 500;
      cursor: pointer;
    }
    &__date {
      font: $fira-14;
      margin-top: 8px;
    }
    &__section {
      display: flex;
      flex-direction: column;
      gap: 4px;
      margin: 24px 0 0 0;
    }
    &__text {
      font: $fira-14;
      font-weight: 500;
    }
    &__label {
      font: $fira-12-M;
      color: $dark-grey-1;
    }
    &__author {
      display: flex;
      align-items: center;
      font-size: 14px;

      img {
        margin-right: 8px;
        width: 32px;
        height: 32px;
        object-fit: cover;
        border-radius: 50%;
        border: 1px solid $black-st !important;
      }
    }
    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  .cards-filter {
    max-width: 980px;
    width: 100%;
    &__input {
      &-container {
        margin-bottom: 16px;
        &:nth-of-type(1) {
          display: flex;
          flex-wrap: wrap;
          .title {
            width: 100%;
            margin-bottom: 16px;
          }
          .cards-filter__input-block {
            position: relative;
            display: flex;
            align-items: center;
            .date {
              max-width: 200px;
              width: 100%;
              margin-left: 8px;
              &:not(:last-of-type) {
                margin-right: 8px;
              }
            }
            .icon {
              position: absolute;
              bottom: 12px;
              right: 12px;
            }
            input[type='text'] {
              max-width: 430px;
            }
          }
        }
      }
    }

    .btns {
      display: flex;
      gap: 8px;
    }
  }
</style>
